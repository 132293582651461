import React, {
  type ReactNode,
  useEffect,
  type FunctionComponent,
} from "react";
import { observer } from "mobx-react-lite";
import AppStore from "~/stores/AppStore";
import { BrowserRouter, useLocation } from "react-router-dom";
import AppContext from "~/contexts/AppContext";
import FlashContext from "~/contexts/FlashContext";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "~/theme";
import type FlashStore from "~/stores/FlashStore";

interface ApplicationProvidersProps {
  appStore: AppStore;
  flashStore: FlashStore;
  children: ReactNode;
}

const ApplicationProviders: FunctionComponent<ApplicationProvidersProps> =
  observer(function ApplicationProviders({ appStore, flashStore, children }) {
    const location = useLocation();

    useEffect(() => {
      flashStore.updateKey(location.key);
    }, [location.key]);

    useEffect(() => {
      appStore.load();
    }, [appStore]);

    return (
      <AppContext.Provider value={appStore}>
        <FlashContext.Provider value={flashStore}>
          <ChakraProvider theme={theme}>{children}</ChakraProvider>
        </FlashContext.Provider>
      </AppContext.Provider>
    );
  });

interface ApplicationProps {
  authenticated: boolean;
  gTranslateConfiguration?: any;
  flashStore: FlashStore;
  children: ReactNode;
}

const Application: FunctionComponent<ApplicationProps> = observer(
  function Application({
    authenticated,
    gTranslateConfiguration,
    children,
    flashStore,
  }) {
    return (
      <BrowserRouter>
        <ApplicationProviders
          appStore={new AppStore(authenticated, gTranslateConfiguration)}
          flashStore={flashStore}
        >
          {children}
        </ApplicationProviders>
      </BrowserRouter>
    );
  }
);

export default Application;
